<script setup>
import axios from 'axios';
import {
  ref, onMounted, defineEmits, defineProps,
} from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';



import {
  // 因為改變 delimiters 出現的問題
  // eslint-disable-next-line no-unused-vars
  transformTypeToId, questionType, toolbarOptions, modulesOptions,
} from '../../methods';

const emit = defineEmits(['closeEdit']);
const props = defineProps(['data']);
const isLoading = ref(true);
const editor = ref(null);

const questionTypesList = ref({});
const getQuestionCategoryMenu = () => {
  axios.get('/v1/questions/selector/types').then((res) => {
    questionTypesList.value = res.data.question_types;
    isLoading.value = false;
  }).catch((error) => {
    isLoading.value = false;
    postMessage('danger', '取得問題分類時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  })
};

const editQuestion = ref({
  title: props.data.title,
  question_type_id: transformTypeToId(props.data.question_type_name),
  body: props.data.body,
});

const limitLength = (key, limit) => {
  if (editQuestion.value[key].length > limit) {
    editQuestion.value[key] = editQuestion.value[key].slice(0, limit);
  }
}

const submitEditQuestion = () => {
  isLoading.value = true;
  if (editQuestion.value.body.length > 16800) {
    alert('問題內容過長（限制 16800 字），請刪除部分文字');
    isLoading.value = false;
    return;
  } else {
    axios.put(`/v1/questions/${props.data.id}`, editQuestion.value).then(() => {
      isLoading.value = false;
      emit('closeEdit', true);
    }).catch((error) => {
      isLoading.value = false;
      postMessage('danger', '編輯問題時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
    })
  }
};
onMounted(() => {
  getQuestionCategoryMenu();
});
</script>

<template>
  <div class="">
    <div class="position-relative">
      <loading :active="isLoading" :is-full-page="false" />
      <div class="">
        <h4 class="mb-0">
          編輯問題
        </h4>
      </div>
      <div class="my-3">
        <label for="title" class="form-label">問題標題 <small>(50 字內)</small></label>
        <input id="title" v-model="editQuestion.title" type="text" class="form-control" aria-describedby="" :class="!editQuestion.title && 'is-invalid'" @blur="limitLength('title', 50)" @keydown="limitLength('title', 50)" placeholder="e.g. 作業繳交">
        <div class="invalid-feedback" :class="!editQuestion.title && 'd-block'">
          問題標題為必填
        </div>
      </div>
      <div class="mb-3">
        <label for="category" class="form-label">問題分類</label>
        <select id="category" v-model="editQuestion.question_type_id" class="form-select"
          aria-label="Default select example">
          <option v-for="qusType in questionTypesList" :key="qusType.name" :value="qusType.id">
            ${ questionType(qusType.name) }
          </option>
        </select>
      </div>
      <div class="mt-3">
        <QuillEditor ref="editor" v-model:content="editQuestion.body" theme="snow" content-type="html"
          :toolbar="toolbarOptions" :modules="modulesOptions" />
        <div class="my-3 d-flex justify-content-between">
          <button class="btn btn-light px-4" @click="$emit('closeEdit', false)">
            取消
          </button>
          <button class="btn btn-hex-primary px-4" :disabled="editQuestion.title === '' || isLoading"
            @click="submitEditQuestion">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
