<script setup>
import { defineProps, defineEmits } from 'vue';
import {
  // eslint-disable-next-line no-unused-vars
  getDiff
} from '../methods';
defineEmits(['readMessage']);
const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  readStatus: {
    type: Boolean,
    default: false,
  },
});
</script>
<template>
  <div class="d-flex">
    <div class="d-flex flex-column align-items-center flex-shrink-0">
      <img
        :src="
          props.item.sender_avatar ||
          'https://s.gravatar.com/avatar/4f3bd4380429c206fdd43aec0a6482e0?d=mm'
        "
        alt=""
        width="48"
        height="48"
        class="rounded-circle"
      />
    </div>
    <div class="media-body ms-2 hex-notification-content">
      <p class="mt-0 mb-0" v-html="props.item.content"></p>
      <small
        ><span class="d-inline-block text-muted"
          >${ getDiff(props.item.create_time) }</span
        ></small
      >
    </div>
  </div>
  <div style="width: 10px">
    <span :class="props.readStatus? 'read' : 'un read'" @click="$emit('readMessage', !props.readStatus, props.item.id)"></span>
  </div>
</template>

<style lang="scss" scoped>
.read {
  flex-shrink: 0;
  width: 12px;
  display: block;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--bs-hex-primary);
  &.un {
    background: var(--bs-hex-primary);
  }
  &:hover {
    border-color: var(--bs-success);
    background: var(--bs-success);
  }
}
</style>
