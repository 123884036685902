<script setup>
import { ref, onMounted, defineProps } from 'vue';
import {
  // eslint-disable-next-line no-unused-vars
  getDiff,
  postMessage,
  checkDomainENV,
} from '../methods';
import axios from 'axios';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Loading from 'vue-loading-overlay';
import ReviewModal from '../components/ReviewModal.vue';
import NotificationItem from '../components/NotificationItem.vue';

const props = defineProps({
  messageId: {
    type: String,
    default: null,
  },
});

onMounted(() => {
  if (props.messageId) {
    readMessage(true, props.messageId);
  }
});

const isLoading = ref(false);
const UnReadListElement = ref(null);
const ReadListElement = ref(null);
// 未讀資料
const userUnreadMessageList = ref({
  messages: [],
  pagination: {},
});
// 已讀資料
const userReadMessageList = ref({
  messages: [],
  pagination: {},
});

// 取得未讀訊息列表
const getUserMessageList = async (page = 1, read = 0) => {
  // page: 頁數, read: 是否已讀。0: 未讀, 1: 已讀
  try {
    isLoading.value = true;
    const userRes = await axios.get(`${['production', 'development'].includes(process.env.VUE_APP_ENV) ? process.env.VUE_APP_API_USER_SYSTEM_URL : ''}/v1/notify/message/list?page=${page}&is_read=${read}`);
    if (page === 1) {
      if (read === 0) {
        userUnreadMessageList.value.messages = userRes.data.messages;
      } else if (read === 1) {
        userReadMessageList.value.messages = userRes.data.messages;
      }
    } else {
      if (read === 0) {
        userUnreadMessageList.value.messages =
          userUnreadMessageList.value.messages.concat(userRes.data.messages);
      } else if (read === 1) {
        userReadMessageList.value.messages =
          userReadMessageList.value.messages.concat(userRes.data.messages);
      }
    }
    if (read === 0) {
      userUnreadMessageList.value.pagination = userRes.data.pagination;
    } else if (read === 1) {
      userReadMessageList.value.pagination = userRes.data.pagination;
    }
  } catch (error) {
    if (error.response.data.statusCode === 403) {
      // 如果是 Permisson Denied 先清除掉 cookie 就好
      const domain = checkDomainENV();
      document.cookie = `rpg_temp_token=;expires=;${
        ['production', 'development'].includes(process.env.VUE_APP_ENV) ? domain : null
      }`;
      document.cookie = `rpg_temp_token_is_link=;expires=;${
        ['production', 'development'].includes(process.env.VUE_APP_ENV) ? domain : null
      }`;
    }
    // if (error.response.data.message === 'token expired') {
    //   if (count.value > 10) {
    //     reGetToken();
    //   } else {
    //     postMessage(
    //       'danger',
    //       '取得站內訊息失敗',
    //       'Token 取得出現異常，請聯絡開發人員。'
    //     );
    //   }
    // } else {
    //   postMessage('danger', '取得站內訊息失敗', error.response.data.message);
    // }
  } finally {
    isLoading.value = false;
  }
};
const firstLoadRead = () => {
  if (
    userReadMessageList.value.messages &&
    userReadMessageList.value.messages.length === 0
  ) {
    getUserMessageList(1, 1);
  }
};
onMounted(() => {
  getUserMessageList(1, 0);
});

const readMessage = async (status, id) => {
  try {
    isLoading.value = true;
    let apiUrl = `${
      ['production', 'development'].includes(process.env.VUE_APP_ENV)
        ? process.env.VUE_APP_API_USER_SYSTEM_URL
        : ''
    }/v1/notify/message/${id}/read`;
    if (!status) {
      apiUrl = `${
        ['production', 'development'].includes(process.env.VUE_APP_ENV)
          ? process.env.VUE_APP_API_USER_SYSTEM_URL
          : ''
      }/v1/notify/message/${id}/unread`;
    }
    await axios.put(apiUrl);
    getUserMessageList(1, 0);
    getUserMessageList(1, 1);
  } catch (error) {
    postMessage('danger', '已讀失敗', error.response.data.message);
  } finally {
    isLoading.value = false;
  }
};

const showDropdown = ref(false);
const activeTab = ref('h_unread_notice');

const questionRecordData = ref({
  questions: [],
  pagination: {},
});
const questionRecordLoading = ref(false);
const firstLoadHistory = () => {
  if (
    questionRecordData.value.questions &&
    questionRecordData.value.questions.length === 0
  ) {
    getQuestionRecord();
  }
};
const getQuestionRecord = (page = 1) => {
  questionRecordLoading.value = true;
  axios.get(`/v1/questions/history?page=${page}`).then((res) => {
    if (page === 1) {
      questionRecordData.value.questions = res.data.questions;
    } else {
      questionRecordData.value.questions =
        questionRecordData.value.questions.concat(res.data.questions);
    }
    questionRecordData.value.pagination = res.data.pagination;
    questionRecordLoading.value = false;
  }).catch((error) => {
    questionRecordLoading.value = false;
    postMessage('danger', '取得問題紀錄發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  });
};

const scrollComponent = ref(null);
onMounted(() => {
  window.addEventListener(
    'scroll',
    function (e) {
      // 未讀的滾動載入
      let UnReadElement = UnReadListElement.value;

      if (
        UnReadElement &&
        e.target.clientHeight + e.target.scrollTop ===
          UnReadElement.scrollHeight
      ) {
        if (
          userUnreadMessageList.value.pagination.total_page >
          userUnreadMessageList.value.pagination.current_page
        ) {
          getUserMessageList(
            userUnreadMessageList.value.pagination.current_page + 1,
            0
          );
        }
      }
      // 已讀的滾動載入
      let ReadElement = ReadListElement.value;
      if (
        ReadElement &&
        e.target.clientHeight + e.target.scrollTop === ReadElement.scrollHeight
      ) {
        if (
          userReadMessageList.value.pagination.total_page >
          userReadMessageList.value.pagination.current_page
        ) {
          getUserMessageList(
            userReadMessageList.value.pagination.current_page + 1,
            1
          );
        }
      }
      // 歷史紀錄的滾動載入
      let element = scrollComponent.value;
      if (
        element &&
        e.target.clientHeight + e.target.scrollTop === element.scrollHeight
      ) {
        if (
          questionRecordData.value.pagination.total_page >
          questionRecordData.value.pagination.current_page
        ) {
          getQuestionRecord(
            questionRecordData.value.pagination.current_page + 1
          );
        }
      }
    },
    true
  );
});

const isReviewModalShow = ref(false);
const reviewContent = ref('');
const reviewType = ref('');
const openReviewModal = (item) => {
  const type = item.content.split('data-type="')[1].split('"')[0];
  const apiUrl = item.content.split('data-url="')[1].split('"')[0];
  // const devLocalUrl = apiUrl.replace("https://dev-hexsystem-qa.hexschool.io", "")
  axios.get(apiUrl).then((res) => {
    reviewContent.value = res.data;
    reviewType.value = type;
    isReviewModalShow.value = true;
    showDropdown.value = false;
  });
};
const closeReviewModal = (value) => {
  isReviewModalShow.value = value;
};
</script>

<template>
  <div class="mt-5">
    <ReviewModal
      v-if="isReviewModalShow"
      @close="closeReviewModal"
      :review-data="reviewContent"
      :review-type="reviewType"
    />
    <div class="right dropdown-container expanded">
      <div
        id="notification"
        class="position-fixed"
        style="bottom: 30px; right: 40px; z-index: 10000"
      >
        <span
          class="h_notice_unread_number"
          v-if="userUnreadMessageList.pagination?.total"
          >${ userUnreadMessageList.pagination.total > 100? '99+' :
          userUnreadMessageList.pagination.total}</span
        >
        <a
          href="#"
          data-dropdown="notificationMenu"
          class="notification-icon d-flex align-items-center justify-content-center rounded-circle text-decoration-none text-hex-primary"
          @click.prevent="showDropdown = !showDropdown"
        >
          <span class="material-icons"> notifications </span>
        </a>
      </div>
      <ul
        name="notificationMenu"
        class="h_dropdown shadow bg-white m-0 p-0 rounded-4 overflow-hidden"
        :class="{ show: showDropdown }"
      >
        <div v-if="isLoading" class="position-absolute top-0 bottom-0 w-100">
          <loading :active="isLoading" :is-full-page="false" />
        </div>
        <div class="tabs">
          <ul class="h_tabs-nav d-flex p-0 list-unstyled">
            <li
              class="flex-fill"
              :class="{ 'tab-active': activeTab == 'h_unread_notice' }"
            >
              <a
                href="#h_unread_notice"
                @click.prevent="activeTab = 'h_unread_notice'"
              >
                未讀通知
              </a>
            </li>
            <li
              class="flex-fill border-start border-end border-dark"
              :class="{ 'tab-active': activeTab == 'h_all_notice' }"
            >
              <a
                href="#h_all_notice"
                @click.prevent="
                  activeTab = 'h_all_notice';
                  firstLoadRead();
                "
              >
                已讀通知
              </a>
            </li>
            <li
              class="flex-fill"
              :class="{ 'tab-active': activeTab == 'question_record' }"
            >
              <a
                class="py-0 h-100 d-flex align-items-center justify-content-center"
                href="#question_record"
                @click.prevent="
                  activeTab = 'question_record';
                  firstLoadHistory();
                "
              >
                <span class="material-icons"> history </span>
              </a>
            </li>
          </ul>
          <div class="h_notice_content">
            <div
              v-if="activeTab == 'h_unread_notice'"
              id="h_unread_notice"
              class="h_notice_area"
            >
              <div ref="UnReadListElement">
                <li
                  v-for="item in userUnreadMessageList.messages"
                  class="notification-group p-2"
                  :key="item.id"
                >
                  <div class="d-flex justify-content-between" v-if="item.content.includes('video_course_review_reply') 
                    || item.content.includes('video_course_review_whisper_reply')" 
                    @click.prevent="openReviewModal(item)">
                    <NotificationItem @read-message="readMessage" :read-status="false" :item="item" />
                  </div>
                  <div class="d-flex justify-content-between" v-else>
                    <NotificationItem @read-message="readMessage" :read-status="false" :item="item" />
                  </div>
                </li>
              </div>
            </div>
            <div
              v-if="activeTab == 'h_all_notice'"
              d="h_all_notice"
              class="h_notice_area"
            >
              <div ref="ReadListElement">
                <li
                  v-for="item in userReadMessageList.messages"
                  class="notification-group p-2"
                  :key="item.id"
                >
                  <div class="d-flex justify-content-between" v-if="item.content.includes('video_course_review_reply') 
                    || item.content.includes('video_course_review_whisper_reply')" 
                    @click.prevent="openReviewModal(item)">
                    <NotificationItem @read-message="readMessage" :read-status="true" :item="item" />
                  </div>
                  <div class="d-flex justify-content-between" v-else>
                    <NotificationItem @read-message="readMessage" :read-status="true" :item="item" />
                  </div>
                </li>
              </div>
            </div>
            <div
              v-show="activeTab == 'question_record'"
              d="question_record"
              class="h_notice_area min-200 position-relative question_record_list"
            >
              <loading :active="questionRecordLoading" :is-full-page="false" />
              <div ref="scrollComponent">
                <div
                  class="px-2 py-1 border-bottom"
                  v-for="history in questionRecordData.questions"
                  :key="history.id"
                >
                  <p class="mb-0">
                    您 ${ history.create_time.split("T")[0] } 在
                    ${history.video_course_lecture_name} 的<a
                      class="link-success"
                      target="_blank"
                      :href="history.url"
                      >發問：${history.title}</a
                    >。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.min-200 {
  min-height: 200px;
}
.notification-icon {
  width: 52px;
  height: 52px;
  font-size: 22px;
  border: 4px solid;
  background-color: var(--bs-gray-100);
  &:hover {
    background-color: var(--bs-gray-300);
  }
}
.h_dropdown {
  z-index: 100000;
  position: fixed;
  right: 40px;
  bottom: 110px;
  max-width: 350px;
  min-width: 350px;
  display: none;
  &.show {
    display: block;
  }
}
.h_tabs-nav li.tab-active a {
  background: transparent;
  border-bottom: 2px solid var(--bs-primary);
  color: var(--bs-primary);
  cursor: default;
}
.h_tabs-nav li a {
  background: var(--bs-gray-300);
  border-bottom: 2px solid var(--bs-gray-300);
  color: var(--bs-secondary);
  display: block;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
}
.h_notice_content {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
}
.notification-group {
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 0px !important;
  overflow: hidden;
  min-height: 65px;
  cursor: pointer;
}
:deep(.hex-notification-content a) {
  color: var(--color-green-100);
}
.h_notice_unread_number {
  width: 25px;
  height: 25px;
  position: absolute;
  background: #de4b4b;
  border-radius: 50%;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}
</style>
